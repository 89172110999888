import { compose, lifecycle, renderNothing } from 'recompose';
import { withRequiredRedirect } from './content/with-required-redirect';

const withRequiredActivityRedirect = compose(
  withRequiredRedirect,
  lifecycle({
    componentDidMount() {
      this.props.processRequiredActivityRedirect();
    },
    componentDidUpdate(prevProps) {
      const prevPathname = prevProps.location.pathname;
      const currentPathname = this.props.location.pathname;

      if (prevPathname !== currentPathname) {
        this.props.processRequiredActivityRedirect();
      }
    }
  })
);

// Moved to a separate component to avoid Content re-rendering
export const RequiredActivityController = withRequiredActivityRedirect(renderNothing);
