import React from 'react';
import { compose } from 'recompose';
import styles from '../../../styles/components/multiple-input.module.scss';
import { InputGroup } from '../../../components/inputs/input-group';
import { withButton } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = ({
  items,
  children,
  onError,
  setFormValues,
  setFormDisabledItems,
  setFormErrorItems,
  setSubmitAdditionalValues
}) => (
  <InputGroup renderAs='div' heir className={styles.controller}>
    {items
      .sort((a, b) => a.order - b.order)
      .map(({ type, data }) => {
        const { userAttributeType, userAttributeField } = data;

        return children({
          key: `${userAttributeType}_${userAttributeField}`,
          type,
          data,
          onError,
          setFormValues,
          setFormDisabledItems,
          setFormErrorItems,
          setSubmitAdditionalValues
        });
      })}
  </InputGroup>
);

export const QuestionGroupInput = compose(withButton, withPreSubmitButton)(Input);
