import React from 'react';
import classnames from 'classnames';
import styles from '../styles/components/coach-message.module.scss';
import { ConfigService } from '../services/config-service';
import { PurifiedHtmlContainer } from './layout/purified-html-container';
import { Image } from './images/image';
import { useSubscribe } from './slots';
import { withRequiredIndicator } from './inputs/required-indicator';

export const CoachAvatar = React.memo(({ className }) => {
  const { avatarUrl, avatarAlt } = ConfigService.get('COACH');

  if (!avatarUrl) return null;

  return (
    <Image
      className={classnames(styles.image, className)}
      src={avatarUrl}
      // Not sure if title (a.k.a. alt) & ariaHidden are needed here in general.
      // Currently, it overrides the image's url with a configured text during Screen Reader announcing.
      // In case we want to the alt for failed image but disable it announcement, we can use ariaHidden=true.
      title={avatarAlt}
      ariaHidden={!!avatarAlt}
    />
  );
});

export const BubbleComponent = React.memo(
  ({ bubblePerItemProp, children, html, parse = { explainer: true }, className }) => {
    const bubblePerItem = ConfigService.get('COACH.bubblePerItem', false);
    return (
      <PurifiedHtmlContainer
        parse={parse}
        html={html}
        className={classnames(
          styles.bubbles,
          bubblePerItemProp ?? bubblePerItem ? styles.plural : styles.singular,
          className
        )}
      >
        {children}
      </PurifiedHtmlContainer>
    );
  }
);

export const CoachBubbles = withRequiredIndicator(BubbleComponent);

const CoachBubblesSlot = React.memo(({ type, name, bubblePerItemProp }) => {
  const bubblePerItem = ConfigService.get('COACH.bubblePerItem', false);

  const slot = useSubscribe(`${type}-${name}-coach-message`);

  return (
    <div
      ref={slot}
      className={classnames(styles.bubbles, bubblePerItemProp ?? bubblePerItem ? styles.plural : styles.singular)}
    />
  );
});

export const CoachMessage = React.memo(
  ({
    prependSlot,
    appendSlot,
    className,
    isAvatarShown = true,
    isUserMessage,
    avatarClassName,
    bubblePerItemProp,
    ...props
  }) => (
    <div
      className={classnames(styles.container, className, {
        [styles.usermsg]: isUserMessage
      })}
    >
      {isAvatarShown && (
        <div className={styles.header}>
          <div className={styles.avatar}>
            <CoachAvatar className={avatarClassName} />
          </div>
        </div>
      )}
      <div className={styles.body}>
        {prependSlot && <CoachBubblesSlot type='prepend' name={prependSlot} bubblePerItemProp={bubblePerItemProp} />}
        <CoachBubbles {...props} bubblePerItemProp={bubblePerItemProp} />
        {appendSlot && <CoachBubblesSlot type='append' name={appendSlot} bubblePerItemProp={bubblePerItemProp} />}
      </div>
    </div>
  )
);
