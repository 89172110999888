import Loadable from 'react-loadable';
import { TLoader } from '../components/template';
import { asTemplate } from '../components/layout/with-layout';
import { AI_ASSISTANT } from '../helpers/navigation';

const AiAssistantPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-ask-me"*/ '../pages/ai-assistant').then(module => module.AssistantPage),
  loading: TLoader
});

export const aiAssistantRoutes = [
  {
    to: '/ai-assistant',
    component: asTemplate(AI_ASSISTANT)(AiAssistantPage)
  }
];
